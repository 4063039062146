/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import isAbsoluteUrl from "is-absolute-url"

const styles = {
  variant: "links.nav",
  // color: "red",
}

export default ({ href, ...props }) => {
  const isExternal = isAbsoluteUrl(href || "")
  if (isExternal) {
    return <a {...props} href={href} sx={styles} />
  }

  const to = props.to || href
  return <Link {...props} to={to} sx={styles} activeClassName="active" />
}
