/** @jsx jsx */
import { Box, Flex, Image, MenuButton } from "@theme-ui/components"
import { AccordionNav } from "@theme-ui/sidenav"
import { Link, graphql } from "gatsby"
import { useRef, useState } from "react"
import { jsx, Styled } from "theme-ui"
import facebook from "../images/facebook-with-circle.png"
import logo from "../images/logo.png"
import tagline from "../images/tagline.png"
import twitter from "../images/twitter-with-circle.png"
import Sidebar from "../sidebar.mdx"
import Banner from "./banner"
import Head from "./head"
import NavLink from "./nav-link"

const sidebar = {
  wrapper: AccordionNav,
  a: NavLink,
}

const Layout = props => {
  const [menuOpen, setMenuOpen] = useState(false)
  const nav = useRef(null)
  const fullwidth =
    (props.pageContext.frontmatter &&
      props.pageContext.frontmatter.fullwidth) ||
    props.location.pathname === "/home"

  const showNav = !props.pageContext?.frontmatter?.hidenav
  const banner = props.pageContext?.frontmatter?.banner

  return (
    <Styled.root>
      <Head {...props} />

      <Flex
        sx={{
          flexDirection: "column",
          minHeight: "100vh",
          border: 0,
          borderTop: 5,
          borderColor: "secondary",
          borderStyle: "solid",
        }}
      >
        {showNav && (
          <Flex
            as="header"
            sx={{
              bg: "primary",
              height: [64, 111],
              px: 3,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Flex sx={{ alignItems: "center" }}>
              <MenuButton
                size={64}
                color="background"
                sx={{
                  display: [null, "none"],
                }}
                onClick={e => {
                  setMenuOpen(!menuOpen)
                  if (!nav.current) return
                  const navLink = nav.current.querySelector("a")
                  if (navLink) navLink.focus()
                }}
              />
              <Link
                to="/"
                sx={{
                  variant: "links.nav",
                  ":hover": {
                    bg: "rgb(255 255 255 / 0.1)",
                  },
                }}
              >
                <Image
                  src={logo}
                  sx={{
                    display: "block",
                    width: [130, 280],
                  }}
                />
              </Link>
            </Flex>

            <Image
              src={tagline}
              sx={{
                display: "block",
                width: [75, 225],
              }}
            />
          </Flex>
        )}

        <Box
          sx={{
            flex: "1 1 auto",
            display: "flex",
            alignItems: "stretch",
          }}
        >
          <div
            sx={{
              display: ["block", "flex"],
              width: "100%",
            }}
          >
            <div
              ref={nav}
              onFocus={e => {
                setMenuOpen(true)
              }}
              onBlur={e => {
                setMenuOpen(false)
              }}
              onClick={e => {
                setMenuOpen(false)
              }}
              sx={{
                bg: "#e1e6ee",
              }}
            >
              <Sidebar
                open={menuOpen}
                components={sidebar}
                pathname={props.location.pathname}
                sx={{
                  bg: "#e1e6ee",
                  display: [null, fullwidth ? "none" : "block"],
                  width: 256,
                  flex: "none",
                  px: 3,
                  pt: 3,
                  pb: 4,
                  mt: [69, 0],
                }}
              />
            </div>

            <div
              sx={{
                width: "100%",
              }}
            >
              {banner && <Banner />}
              <main
                id="content"
                sx={{
                  width: "100%",
                  minWidth: 0,
                  maxWidth: fullwidth ? "none" : 768,
                  mx: "auto",
                  px: fullwidth ? 0 : 3,
                  pb: 4,
                  ".use-grid + p": {
                    display: "grid",
                    gridGap: 2,
                    gridAutoFlow: ["row", "column"],
                  },
                }}
              >
                {props.children}
              </main>
            </div>
          </div>
        </Box>

        <footer
          sx={{
            p: 4,
            fontSize: 0,
            textAlign: "center",
            bg: "primary",
            color: "background",
            a: {
              color: "inherit",
            },
          }}
        >
          <Image
            src={logo}
            sx={{
              width: [130, 230],
              mb: 4,
            }}
          />
          <br />
          1318 High Street Malvern, 3144 Victoria
          <br />
          Telephone: <a href="tel:0395082100">(03) 9508 2100</a> &nbsp; Email:{" "}
          <a href="https://www.delasalle.vic.edu.au/contact/enquiries">
            Please use our contact form
          </a>
          <br />
          Find us on{" "}
          <a href="https://maps.google.com.au/maps?q=De+La+Salle+College,+High+Street,+Malvern,+Victoria&hl=en&sll=-37.801099,145.038763&sspn=0.016632,0.026886&vpsrc=0&hq=De+La+Salle+College,&hnear=High+St,+Malvern+Victoria+3144&t=h&cid=793313339689561974&z=17&iwloc=A">
            Google Maps
          </a>
          <br />© Copyright {new Date().getFullYear()} – De La Salle College
          <br />
          <br />
          <Flex
            sx={{
              justifyContent: "center",
              a: {
                mx: 1,
              },
            }}
          >
            <a href="https://www.facebook.com/delasallecollege">
              <Image src={facebook} />
            </a>
            <a href="https://twitter.com/dlsMalvern">
              <Image src={twitter} />
            </a>
          </Flex>
        </footer>
      </Flex>
    </Styled.root>
  )
}

export default Layout
