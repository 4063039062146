import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/"
        }}>{`Welcome`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/enrolment"
        }}>{`Enrolment`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/enrolment#how-do-i-enrol-my-son"
            }}>{`How do I Enrol my Son?`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/enrolment#when-should-i-enrol-my-son"
            }}>{`When Should I Enrol my Son?`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/enrolment#fees"
            }}>{`Fees`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/enrolment#uniforms"
            }}>{`Uniforms`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/college-tours"
        }}>{`College Tours`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/college-information-videos"
        }}>{`College Information Videos`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/curriculum-handbook"
        }}>{`Curriculum Handbook 2020`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/contact"
        }}>{`Contact`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      