// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---content-college-information-videos-mdx": () => import("./../content/college-information-videos.mdx" /* webpackChunkName: "component---content-college-information-videos-mdx" */),
  "component---content-college-tours-mdx": () => import("./../content/college-tours.mdx" /* webpackChunkName: "component---content-college-tours-mdx" */),
  "component---content-contact-mdx": () => import("./../content/contact.mdx" /* webpackChunkName: "component---content-contact-mdx" */),
  "component---content-curriculum-handbook-mdx": () => import("./../content/curriculum-handbook.mdx" /* webpackChunkName: "component---content-curriculum-handbook-mdx" */),
  "component---content-enrolment-mdx": () => import("./../content/enrolment.mdx" /* webpackChunkName: "component---content-enrolment-mdx" */),
  "component---content-index-mdx": () => import("./../content/index.mdx" /* webpackChunkName: "component---content-index-mdx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

